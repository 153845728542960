import {Component} from 'preact';
import {route, Router} from 'preact-router';

import {IntlProvider} from 'preact-i18n';
import defaultDefinition from '../config/i18n/de.json';

import Header from './header';
import Footer from './footer';

// Code-splitting is automated for routes
import Home from '../routes/home';

// Static Sites
import HelpContactDe from '../routes/static_content/help_contact/index_de';
import Tracking from '../routes/tracking';
import CookieBanner from './cookie_banner/index.js';

import {GAPageView, initGA} from "./google_tracking";
import AboutUsDe from "../routes/static_content/about_us/index_de";
import CustomerServiceDe from "../routes/static_content/customer_service/index_de";
import {createApi} from "./utils/userlike_utils";

const avaibleLanguages = ['de'];

export default class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            languageDefinition: defaultDefinition,
            language: 'de',
            current_page: '/',
            tracking_cookie: false,
            technical_cookie: false,
            cookie_info_open: false,
            userlike: null,
            analytics_initialized: false,
            current_tracking_id: null
        };
        this.changeLanguage = this.changeLanguage.bind(this);
        this.update_cookie = this.update_cookie.bind(this);
        this.set_cookie_info_open = this.set_cookie_info_open.bind(this);
    }

    handleRoute = e => {
        if (!e.current) {
            route("/");
        }
        if (this.state.tracking_cookie) {
            GAPageView(e.url);
        }
        this.setState({current_page: e.url, current_tracking_id: e.current ? e.current.props.tracking_id : null});
    };

    update_cookie = (tracking_cookie) => {
        this.setState({technical_cookie: true, tracking_cookie: tracking_cookie});
        this.save_data_to_cookie(tracking_cookie);
    };

    set_cookie_info_open = () => {
        this.setState({cookie_info_open: !this.state.cookie_info_open})
    };

    changeLanguage = language => {
        this.setState({language});
        if (avaibleLanguages.includes(language)) {
            import(`../config/i18n/${language}.json`).then(languageDefinition => this.setState({languageDefinition}));
        }
    };

    componentDidMount() {
        this.get_data_from_cookie();
        this.initUserlike();
    }

    get_data_from_cookie = () => {
        let cookies = document.cookie.split(';');
        cookies.forEach(cookie => {
            cookie = cookie.trim().split('=');
            if (cookie[0] === 'ncc_technical_cookie') {
                this.setState({technical_cookie: true})
            }
            if (cookie[0] === 'ncc_tracking_cookie') {
                this.setState({tracking_cookie: true})
            }
        })
    };

    save_data_to_cookie = (tracking_cookie) => {
        document.cookie = `ncc_technical_cookie=true;path=/`;
        if (tracking_cookie) {
            document.cookie = `ncc_tracking_cookie=true;path=/`;
        } else {
            document.cookie = 'ncc_tracking_cookie=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/';
        }
    };

    isAlreadyMounted() {
        let isMounted = false;
        if (document.querySelector('div[id^="userlike-"]')) {
            const userlikeTag = document.getElementById(document.querySelector('div[id^="userlike-"]').id)
            isMounted = userlikeTag.isConnected;
        }
        return isMounted;
    }

    async initUserlike() {
        const api = await createApi();
        if (api) {
            if (!this.isAlreadyMounted()) {
                api.mount().subscribe(result => {
                    if (result.kind === "error" || result.value === null) {
                        return;
                    }
                    this.setState({ userlike: result.value });
                });
            }
        }
    }

    initAnalytics() {
        initGA();
        this.setState({analytics_initialized: true});
    }

    setCustomData = (tracking_id) => {
        this.state.userlike?.setCustomData({ tracking_id: tracking_id });
    }

    render({}, {
        languageDefinition,
        language,
        current_page,
        technical_cookie,
        tracking_cookie,
        cookie_info_open,
        userlike,
        analytics_initialized,
        current_tracking_id
    }) {
        let extra_space_for_cookie_banner = !technical_cookie ? 'with-cookie-banner' : '';
        const ga_active = tracking_cookie;
        if (!analytics_initialized && ga_active) {
            this.initAnalytics();
        }
        return (
            <IntlProvider definition={languageDefinition}>
                <div id="app" className={extra_space_for_cookie_banner}>
                    <Header key="header" current_page={current_page} changeLanguage={this.changeLanguage}
                            language={language}/>
                    <div id="content">
                        <div id="background_image"></div>
                        <Router onChange={this.handleRoute}>
                            <Home path="/"/>

                            <HelpContactDe path="/de/faq"/>
                            <HelpContactDe path="/de/faq"/>

                            <AboutUsDe path="/de/ueber-uns"/>
                            <AboutUsDe path="/de/about-us"/>

                            <CustomerServiceDe path="/de/kundenservice"/>
                            <CustomerServiceDe path="/de/customerservice"/>

                            <Tracking path="/de/sendungsverfolgung/:tracking_id" is_ga_active={ga_active} set_custom_data={this.setCustomData}/>
                            <Tracking path="/en/sendungsverfolgung/:tracking_id" is_ga_active={ga_active} set_custom_data={this.setCustomData}/>

                            <Tracking path="/de/tracking/:tracking_id" is_ga_active={ga_active} set_custom_data={this.setCustomData}/>
                            <Tracking path="/en/tracking/:tracking_id" is_ga_active={ga_active} set_custom_data={this.setCustomData}/>

                            <Tracking path="/de/sendungsverfolgung" is_ga_active={ga_active}/>
                            <Tracking path="/en/sendungsverfolgung" is_ga_active={ga_active}/>

                            <Tracking path="/de/tracking" is_ga_active={ga_active}/>
                            <Tracking path="/en/tracking" is_ga_active={ga_active}/>
                        </Router>
                    </div>
                    <Footer key="footer" cookie_info_open={cookie_info_open} cookie_banner_present={!technical_cookie}/>
                    {technical_cookie ? null :
                        <CookieBanner update_cookie={this.update_cookie} set_cookie_open={this.set_cookie_info_open}/>}
                </div>
            </IntlProvider>
        );
    }
}
