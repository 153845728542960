import {Component} from 'preact';
import {Text, MarkupText} from 'preact-i18n';

export default class CookieBanner extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tracking_cookie: false,
            show_more: false
        };
        this.saveCookie = this.saveCookie.bind(this);
        this.saveAllCookie = this.saveAllCookie.bind(this);
    }

    toggle_tracking = (e) => {
        e.preventDefault();
        this.setState({tracking_cookie: !this.state.tracking_cookie});
    };

    toggle_show_more = () => {
        this.setState({show_more: !this.state.show_more});
        this.props.set_cookie_open();
    };

    saveCookie() {
        this.props.update_cookie(this.state.tracking_cookie, this.state.functional_cookie)
    }

    saveAllCookie() {
        this.props.update_cookie(true, true)
    }

    render({}, {tracking_cookie, functional_cookie, show_more}) {

        return (
            <section id="cookie-banner">
                <div className="container">
                    <div className="item is-bold">
                        <h2><Text id={`cookie.info_header`}/></h2>
                    </div>
                    <div className="item">
                        <Text id={`cookie.info1`}/>
                        <br/>
                        <MarkupText id={`cookie.info2`}/>
                    </div>
                    <div className="item">
                        <label className="checkbox" disabled>
                            <input type="checkbox" checked disabled/> &nbsp;
                            <Text id={`cookie.tech_cookie`}/>
                        </label>
                    </div>
                    <div className="item">
                        <label className="checkbox">
                            <input type="checkbox" checked={tracking_cookie} onChange={this.toggle_tracking}/> &nbsp;
                            <Text id={`cookie.track_cookie`}/>
                        </label>
                    </div>
                    <div>
                        {show_more ?
                            <a className="button is-text" onClick={this.toggle_show_more}>
                                <span><Text id="cookie.less"/></span>
                                <span className="has-icon-font" style={{marginLeft: '0.3em'}}>^</span>
                            </a>
                            :
                            <a className="button is-text" onClick={this.toggle_show_more}>
                                <span><Text id="cookie.more"/></span>
                                <span className="has-icon-font" style={{marginLeft: '0.3em'}}>v</span>
                            </a>
                        }
                    </div>
                        {show_more ?
                            <div className="items">
                                <div className="item">
                                    <h3><Text id={`cookie.tech_cookie`}/></h3>
                                    <Text id={`cookie.tech_cookie_info`}/>
                                </div>
                                <div className="item">
                                    <h3><Text id={`cookie.track_cookie`}/></h3>
                                    <Text id={`cookie.track_cookie_info`}/>
                                </div>
                            </div>
                            : null}
                    <div className="item">
                        <div className="control">
                            <button className="button is-warning is-fullwidth is-uppercase has-text-white" onClick={this.saveAllCookie}>
                                <Text id={`cookie.save_all`}/>
                            </button>
                        </div>
                        <div className="control">
                            <button className="button is-fullwidth is-uppercase" onClick={this.saveCookie}>
                                <Text id={`cookie.save`}/>
                            </button>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
