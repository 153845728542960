import {createMessenger} from "@userlike/messenger";
import {CONFIG} from "../../config/config";

export async function createApi() {
    const result = await createMessenger({
        version: 2,
        widgetKey: CONFIG.USERLIKE_WIDGET_KEY
    });
    if (result.kind !== "error") {
        const {api} = result.value;
        if (api) {
          return api;
        }
    }
}
